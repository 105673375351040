import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Button, Modal, ModalBody } from "react-bootstrap";
import { isMobileOrTablet } from "../Utils";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserWayAccessibilityMenu from "../UserWayAccessibilityMenu/index.js";
import "./index.css";
import SignUpForm from "../SignUpForm/SignUpForm";

export default function NavigationBar({data}) {
    const [navbar, setNavbar] = useState(false);
    const isMobileOrTabletDevice = isMobileOrTablet();
    const [expanded, setExpanded] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const imgCDNURL = process.env.REACT_APP_CDN + "angelai-life/";
    const location = useLocation();
    const navigate = useNavigate();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showFailModal, setShowFailModal] = useState(false);
    const{truApprovalText, truApprovalLink} = data?.navbar
    const [validated, setValidated] = useState(false);
    let prevScrollPos = window.scrollY;
    const handleFailClose = () => {
        setShowFailModal(false);
        setValidated(false);
    };
    const handleSuccessClose = () => {
        setShowSuccessModal(false);
        setValidated(false);
        
    };
    useEffect(() => {
        // Scroll to top on route change
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location.pathname]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    function scrollToSection2() {
        const sectionPosition =
            document.getElementById("contact-wrapper").offsetTop;
        window.scrollTo({
            top: sectionPosition - 50,
            behavior: "smooth",
        });
    }

    const handleShowForm = () => {
        setShowForm(true);
    };
    const handleCloseForm = () => {
        setShowForm(false);
    };

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        const menuNavbar = document.getElementById("menuNavbar");
        if (currentScrollPos === 0) {
            menuNavbar.classList.remove("navbarTransition");
            setNavbar(false);
        } else {
            if (prevScrollPos > currentScrollPos) {
                setNavbar(true);
            } else if (prevScrollPos < currentScrollPos) {
                if (!isMobileOrTabletDevice) {
                    setNavbar(true);
                } else {
                    setNavbar(false);
                    menuNavbar.classList.add("navbarTransition");
                }
            }
        }
        prevScrollPos = currentScrollPos;
    };

    const handleMenuClick = (path) => {
        if (location.pathname === path) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
            navigate(path);
        }
        setExpanded(false);
        setNavbar(false);
    };

    const handleContactUsClick = () => {
        if (location.pathname === "/") {
            scrollToSection2();
        } else {
            setShowForm(true);
        }
        setNavbar(true);
    };

    return (
        <>
            <div>
                <Navbar
                    id="menuNavbar"
                    collapseOnSelect
                    expanded={expanded}
                    onToggle={() => setExpanded(!expanded)}
                    onSelect={() => setExpanded(false)}
                    expand="lg"
                    fixed="top"
                    bg="dark"
                    variant="dark"
                    className={navbar ? "navbar active" : "navbar"}
                >
                    <Container className="navbar-container">
                        <Navbar.Brand className="navbrand-logo">
                            <div className="d-flex">
                                <div>
                                    <a href="https://www.swmc.com/" >
                                        <img
                                            src={imgCDNURL + "angelai-life-logo.webp"}
                                            alt="AngelAi.Life logo"
                                            width="494px"
                                            height="184px"
                                            className="main_logo img-fluid"
                                        />
                                    </a>
                                </div>
                                <UserWayAccessibilityMenu color="white" width={30} height={30} />
                            </div>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav>
                                <Nav.Link as={Link} to="/" onClick={() => handleMenuClick("/")}>
                                    Home
                                </Nav.Link>
                             
                                <Nav.Link
                                    as={Link} to="/loan-programs" 
                                    onClick={() => handleMenuClick("/loan-programs")}
                                >
                                    Loan Services
                                </Nav.Link>
                                <Nav.Link
                                onClick={() => setExpanded(false)}
                                >
                                    <Button className='contact-us-btn' onClick={handleContactUsClick} variant="outline-warning">Contact Us</Button>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <Modal show={showForm} onHide={handleCloseForm} size="lg">
                <ModalBody className='form-modal'>
                    <button className="close-modal-button" onClick={handleCloseForm}>X</button>
                    <SignUpForm closeForm={handleCloseForm} requestFromModal={true} setShowSuccessModal={setShowSuccessModal} setShowFailModal={setShowFailModal}/>
                </ModalBody>
            </Modal>
            <Modal
                    size="lg"
                    centered
                    show={showSuccessModal}
                    onHide={handleSuccessClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton />
                    <Modal.Body className="text-center">
                        <p className="thanktext">Thank you for sharing your feedback!</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleSuccessClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    size="lg"
                    centered
                    show={showFailModal}
                    onHide={handleFailClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <p className="thanktext">
                            Due to technical difficulties your request could not be processed.
                            Please contact us at&nbsp;
                            <a href={"mailto:inquiry@swmc.com"} className="form-link">
                                inquiry@swmc.com
                            </a>
                            .
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleFailClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
        </>
    );
}
