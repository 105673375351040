import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import CommonBgComp from "../CommonBgComp";
import parser from "html-react-parser";
import "./PrivacyPolicy.css";

function Privacy() {
  const imgCDNURL = process.env.REACT_APP_CDN + "evolutionai/images/";
  const [policyData, setPolicyData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_CDN + "angelai-life/jsons/angelAiLifePrivacy.json"
        );
        setPolicyData(response?.data?.ANGELAILIFE_Privacy);
      } catch (error) {
        console.error("Error fetching policy data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div>
        <CommonBgComp
          backgroundImage={imgCDNURL + "privacy-policy.webp"}
          text="Our Security and Privacy Policy"
          fontSizeDesktop={60}
          fontSizeTablet={30}
          fontSizeMobile={19}
        />
      </div>
      <Container
        id="terms-wrapper1"
        className="py-3"
        style={{ textAlign: "justify" }}
      >
        {policyData && (
          <>
            <h2 className="policy-policy-heading">{parser(policyData.title)}</h2>
            <Accordion>
              <Accordion.Item className="questioncelli">
                <Accordion.Header>
                  {policyData.factsections &&
                    policyData.factsections.map((factSection, index) => (
                      <div key={index}>
                        <h6 className="accordianhead">
                          {parser(factSection.section_title)}
                        </h6>
                      </div>
                    ))}
                </Accordion.Header>
                <Accordion.Body>
                  {policyData.factsections &&
                    policyData.factsections.map((factSection, index) => (
                      <div key={index}>
                        <p className="privacy-what-does">  
                        {parser( factSection.section_content)}
                      </p>
                      </div>
                    ))}
                  {/* Content for the "Why?" section */}
                  <Accordion>
                    <Accordion.Item eventKey="Why?">
                      <Accordion.Header>
                        <h6 className="accordianhead">
                          {parser(policyData.whysection[0].section_header)}
                        </h6>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          {" "}
                          {parser(policyData.whysection[0].section_text)}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="What?">
                      <Accordion.Header>
                        <h6 className="accordianhead">
                          {parser(
                            policyData.whatsection[0].section_description
                          )}
                        </h6>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          {" "}
                          {parser(policyData.whatsection[0].section_detail)}
                        </p>
                        <ul>
                          <li>
                            {" "}
                            {parser(
                              policyData.whatsection[0].section_list1
                            )}
                          </li>
                          <li>
                            {" "}
                            {parser(
                              policyData.whatsection[0].section_list2
                            )}
                          </li>
                          <li>
                            {" "}
                            {parser(
                              policyData.whatsection[0].section_list3
                            )}
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="How?">
                      <Accordion.Header>
                        <h6 className="accordianhead">
                          {parser(policyData.howsection[0].section_heading)}
                        </h6>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          {" "}
                          {parser(policyData.howsection[0].section_body)}
                        </p>
                        <Container className="tablescroll">
                          <Row>
                            <Col>
                              <div>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>
                                        {parser(
                                          policyData.howsection[0]
                                            .section_tabletile1
                                        )}
                                      </th>
                                      <th>
                                        {parser(
                                          policyData.howsection[0]
                                            .section_tabletile2
                                        )}
                                      </th>
                                      <th>
                                        {parser(
                                          policyData.howsection[0]
                                            .section_tabletile3
                                        )}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {policyData.howsection[0].data.map(
                                      (item, index) => (
                                        <tr key={index}>
                                          <td>
                                            {parser(
                                              item[
                                              "Reasons we can share your personal information"
                                              ]
                                            )}
                                          </td>
                                          <td>
                                            <b>
                                              {parser(
                                                item["Does Celligence share?"]
                                              )}
                                            </b>
                                          </td>
                                          <td>
                                            <b>
                                              {parser(
                                                item[
                                                "Can you limit this sharing?"
                                                ]
                                              )}
                                            </b>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="To limit our sharing">
                      <Accordion.Header>
                        <h6 className="accordianhead">
                          {policyData.Tolimitoursharing.sharingtitle}
                        </h6>
                      </Accordion.Header>
                      <Accordion.Body>
                        {" "}
                        <div>
                          <div>
                            <ul>
                              <li>
                                Call{" "}
                                <a
                                  href={
                                    policyData.Tolimitoursharing.sharingcall
                                  }
                                >
                                  {policyData.Tolimitoursharing.call}
                                </a>{" "}
                                <b>or</b>
                              </li>
                              <li>
                                {parser(
                                  policyData.Tolimitoursharing.sharingemailnotDBA
                                )}
                              </li>
                            </ul>{" "}
                            {parser(policyData.Tolimitoursharing.note)} <br />
                            <br />
                            {parser(policyData.Tolimitoursharing.however)}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="Questions?">
                      <Accordion.Header>
                        <h6 className="accordianhead">
                          {parser(policyData.Questions.questions)}
                        </h6>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <div>
                            Call{" "}
                            <a href={policyData.Questions.questionscall}>
                              {policyData.Questions.call}
                            </a>
                          </div>
                        </div>{" "}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="What we do">
                      <Accordion.Header>
                        <h6 className="accordianhead">
                          {parser(policyData.whatwedo.whatwedotitle)}
                        </h6>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className=" align-items-center">
                          <Col md={2} className="d-none d-md-block">
                            <img
                              src={parser(policyData.whatwedo.whatweimg)}
                              alt="stock images"
                              className="img-fluid"
                              loading="lazy"
                            />
                          </Col>
                          <Col md={10} xs={12}>
                            <h3>{parser(policyData.whatwedo.whattitle)}</h3>
                            <div>{parser(policyData.whatwedo.whatcontent)}</div>
                          </Col>
                        </Row>
                        <Row className="mt-5 align-items-center">
                          <Col md={2} className="d-none d-md-block">
                            <img
                              src={parser(policyData.whatwedo.whatweimg1)}
                              alt="stock images"
                              className="img-fluid"
                              loading="lazy"
                            />
                          </Col>
                          <Col md={10} xs={12}>
                            <h3>{parser(policyData.whatwedo.whattitle1)}</h3>
                            <div>{parser(policyData.whatwedo.loancontent)}</div>
                          </Col>
                        </Row>

                        <Row className=" mt-5 align-items-center">
                          <Col md={2} className="d-none d-md-block">
                            <img
                              src={parser(policyData.whatwedo.limitimage)}
                              alt="stock images"
                              className="img-fluid"
                              loading="lazy"
                            />
                          </Col>
                          <Col md={10} xs={12}>
                            <h3>{parser(policyData.whatwedo.limittitle)}</h3>
                            <div>
                              {parser(policyData.whatwedo.limitcontent)}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-5 align-items-center">
                          <Col md={2} className="d-none d-md-block">
                            <img
                              src={parser(policyData.whatwedo.happenimage)}
                              alt="stock images"
                              className="img-fluid"
                              loading="lazy"
                            />
                          </Col>
                          <Col md={10} xs={12}>
                            <h3>{parser(policyData.whatwedo.happentitle)}</h3>
                            <div>
                              {parser(policyData.whatwedo.happencontent)}
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div>
              <Accordion>
                <Accordion.Item eventKey="facts" className="questioncelli">
                  <Accordion.Header>
                    <h6 className="accordianhead">
                      {parser(policyData.definations.definationtitle)}
                    </h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row className=" mt-1 align-items-center">
                      <Col className=" def-image d-none d-md-block">
                        <img
                          src={parser(policyData.definations.definationimg)}
                          alt="stock images"
                          className="img-fluid Affiliatesnimg"
                          loading="lazy"
                        />
                      </Col>
                      <Col md={10} xs={12}>
                        <h3>
                          {parser(policyData.definations.affiliatetitle)}
                        </h3>
                        <div>
                          {parser(policyData.definations.affiliatescontent)}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-1 align-items-center">
                      <Col className="def-image d-none d-md-block">
                        <img
                          src={parser(
                            policyData.definations.NonAffiliatesnimg
                          )}
                          alt="stock images"
                          class="img-fluid NonAffiliatesnimg"
                          loading="lazy"
                        />
                      </Col>
                      <Col md={10} xs={12}>
                        <h3>
                          {parser(
                            policyData.definations.NonAffiliatesntitle
                          )}
                        </h3>
                        <div>
                          {parser(
                            policyData.definations.NonAffiliatesncontent
                          )}
                        </div>
                        <h3 className="mt-5 mt-md-1">
                          {parser(policyData.definations.jointitle)}
                        </h3>
                        <div>
                          {parser(policyData.definations.joincontent)}
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div>
              <Accordion>
                <Accordion.Item
                  eventKey="-STATE SPECIFIC PRIVACY NOTICE-"
                  className="questioncelli"
                >
                  <Accordion.Header>
                    <h6 className="accordianhead">
                      {parser(
                        policyData.statespecificprivacypolicy.statetitle
                      )}
                    </h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* Content for the "Why?" section */}
                    <Accordion>
                      <Accordion.Item eventKey="Notice for California Residents">
                        <Accordion.Header>
                          <h6>
                            {parser(
                              policyData.statespecificprivacypolicy
                                .californiaresident
                            )}
                          </h6>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Container className="tablescroll">
                            <Row>
                              <Col>
                                <div>
                                  <div style={{ textAlign: "right" }}>
                                    <p>
                                      {parser(
                                        policyData
                                          .NoticeforCaliforniaResidents.date
                                      )}
                                    </p>
                                  </div>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td >
                                          <b>
                                            {parser(
                                              policyData
                                                .NoticeforCaliforniaResidents
                                                .facttbletitle
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          {parser(
                                            policyData
                                              .NoticeforCaliforniaResidents
                                              .facttablecontent
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {parser(
                                              policyData
                                                .NoticeforCaliforniaResidents
                                                .whytitle
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          {parser(
                                            policyData
                                              .NoticeforCaliforniaResidents
                                              .whycont
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {parser(
                                              policyData
                                                .NoticeforCaliforniaResidents
                                                .whattitletb
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          <div>
                                            {parser(
                                              policyData
                                                .NoticeforCaliforniaResidents
                                                .whatcontent
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {parser(
                                              policyData
                                                .NoticeforCaliforniaResidents
                                                .howtitle
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          {parser(
                                            policyData
                                              .NoticeforCaliforniaResidents
                                              .howcontent
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <thead>
                                      <tr>
                                        {policyData.NoticeforCaliforniaResidents.questiontable.question.map(
                                          (header, index) => (
                                            <th key={index}>{header}</th>
                                          )
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {policyData.NoticeforCaliforniaResidents.questiontable.questionrows.map(
                                        (row, index) => (
                                          <tr key={index}>
                                            <td>{parser(row.reason)}</td>
                                            <td><b>{parser(row.share)}</b></td>
                                            <td><b>{parser(row.limit)}</b></td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>

                                  <table className="starttavle">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>{policyData.tostart}</b>
                                        </td>
                                        <td>
                                          <div>
                                            {parser(
                                              policyData.tostartcontent
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {" "}
                                            {parser(
                                              policyData.calliquestion
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          {parser(policyData.callicontent)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <thead>
                                      <tr>
                                        <th>What we do</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {policyData.whytotable.whytoquestions.map(
                                        (row, index) => (
                                          <tr key={index}>
                                            <td>{parser(row.question)}</td>
                                            <td>{parser(row.answer)}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <thead>
                                      <tr>
                                        <th>Definitions</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {policyData.definationtable.tabledefinitions.map(
                                        (definition, index) => (
                                          <tr key={index}>
                                            <td>
                                              {parser(definition.term)}
                                            </td>
                                            <td>
                                              <div>
                                                {parser(
                                                  definition.definition
                                                )}
                                                <br></br>

                                                {parser(definition.details)}
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        eventKey="Notice for North Dakota Residents"
                        className=""
                      >
                        <Accordion.Header>
                          <h6>
                            {" "}
                            {parser(
                              policyData.statespecificprivacypolicy
                                .dakotatitle
                            )}
                          </h6>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Container className="tablescroll">
                            <Row>
                              <Col>
                                <div>
                                  <div style={{ textAlign: "right" }}>
                                    <p>
                                      {parser(
                                        policyData
                                          .NoticeforNorthDakotaResidents
                                          .date
                                      )}
                                    </p>
                                  </div>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {parser(
                                              policyData
                                                .NoticeforNorthDakotaResidents
                                                .facttbletitle
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          {parser(
                                            policyData
                                              .NoticeforNorthDakotaResidents
                                              .facttablecontent
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {parser(
                                              policyData
                                                .NoticeforNorthDakotaResidents
                                                .whytitle
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          {parser(
                                            policyData
                                              .NoticeforNorthDakotaResidents
                                              .whycont
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {parser(
                                              policyData
                                                .NoticeforNorthDakotaResidents
                                                .whattitletb
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          <div>
                                            {parser(
                                              policyData
                                                .NoticeforNorthDakotaResidents
                                                .whatcontent
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {parser(
                                              policyData
                                                .NoticeforNorthDakotaResidents
                                                .howtitle
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          {parser(
                                            policyData
                                              .NoticeforNorthDakotaResidents
                                              .howcontent
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <thead>
                                      <tr>
                                        {policyData.NoticeforNorthDakotaResidents.questiontable.question.map(
                                          (header, index) => (
                                            <th key={index}>{header}</th>
                                          )
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {policyData.NoticeforNorthDakotaResidents.questiontable.questionrows.map(
                                        (row, index) => (
                                          <tr key={index}>
                                            <td>{parser(row.reason)}</td>
                                            <td><b>{parser(row.share)}</b></td>
                                            <td><b>{parser(row.limit)}</b></td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>

                                  <table className="starttavle">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>{policyData.dakotatostart}</b>
                                        </td>
                                        <td>
                                          <div>
                                            {parser(
                                              policyData.dakotatostartcontent
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {" "}
                                            {parser(
                                              policyData.dakotacalliquestion
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          {parser(
                                            policyData.dakotacallicontent
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <thead>
                                      <tr>
                                        <th>What we do</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {policyData.dakotawhytotable.whytoquestions.map(
                                        (row, index) => (
                                          <tr key={index}>
                                            <td>{parser(row.question)}</td>
                                            <td>{parser(row.answer)}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <thead>
                                      <tr>
                                        <th>Definitions</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {policyData.dakotadefinationtable.tabledefinitions.map(
                                        (definition, index) => (
                                          <tr key={index}>
                                            <td>
                                              {parser(definition.term)}
                                            </td>
                                            <td>
                                              <div>
                                                {parser(
                                                  definition.definition
                                                )}
                                                <br></br>

                                                {parser(definition.details)}
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                  <table className="question">
                                    <thead>
                                      <tr>
                                        <th>Other important information</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div>
                                            {parser(policyData.otherinfo)}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item
                        // className="mb-3"
                        eventKey="Notice for Vermont Residents"
                      >
                        <Accordion.Header>
                          <h6>
                            {parser(
                              policyData.statespecificprivacypolicy.varmont
                            )}
                          </h6>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Container className="tablescroll">
                            <Row>
                              <Col>
                                <div>
                                  <div style={{ textAlign: "right" }}>
                                    <p>
                                      {parser(
                                        policyData.NoticeforVermontResidents
                                          .date
                                      )}
                                    </p>
                                  </div>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {parser(
                                              policyData
                                                .NoticeforVermontResidents
                                                .facttbletitle
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          {parser(
                                            policyData
                                              .NoticeforVermontResidents
                                              .facttablecontent
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {parser(
                                              policyData
                                                .NoticeforVermontResidents
                                                .whytitle
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          {parser(
                                            policyData
                                              .NoticeforVermontResidents
                                              .whycont
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {parser(
                                              policyData
                                                .NoticeforVermontResidents
                                                .whattitletb
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          <div>
                                            {parser(
                                              policyData
                                                .NoticeforVermontResidents
                                                .whatcontent
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {parser(
                                              policyData
                                                .NoticeforVermontResidents
                                                .howtitle
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          {parser(
                                            policyData
                                              .NoticeforVermontResidents
                                              .howcontent
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <thead>
                                      <tr>
                                        {policyData.NoticeforVermontResidents.questiontable.question.map(
                                          (header, index) => (
                                            <th key={index}>{header}</th>
                                          )
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {policyData.NoticeforVermontResidents.questiontable.questionrows.map(
                                        (row, index) => (
                                          <tr key={index}>
                                            <td>{parser(row.reason)}</td>
                                            <td><b>{parser(row.share)}</b></td>
                                            <td><b>{parser(row.limit)}</b></td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>

                                  <table className="starttavle">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>{policyData.Vermonttostart}</b>
                                        </td>
                                        <td>
                                          <div>
                                            {parser(
                                              policyData.Vermonttostartcontent
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <b>
                                            {" "}
                                            {parser(
                                              policyData.Vermontcalliquestion
                                            )}
                                          </b>
                                        </td>
                                        <td>
                                          {parser(
                                            policyData.Vermontcallicontent
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <thead>
                                      <tr>
                                        <th>What we do</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {policyData.Vermontwhytotable.whytoquestions.map(
                                        (row, index) => (
                                          <tr key={index}>
                                            <td>{parser(row.question)}</td>
                                            <td>{parser(row.answer)}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>

                                  <table className="question">
                                    <thead>
                                      <tr>
                                        <th>Definitions</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {policyData.Vermontdefinationtable.tabledefinitions.map(
                                        (definition, index) => (
                                          <tr key={index}>
                                            <td>
                                              {parser(definition.term)}
                                            </td>
                                            <td>
                                              <div>
                                                {parser(
                                                  definition.definition
                                                )}
                                                <br></br>

                                                {parser(definition.details)}
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                  <table className="question">
                                    <thead>
                                      <tr>
                                        <th>Other important information</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {parser(
                                            policyData.Otherimportantinformation
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div>
              <Accordion>
                <Accordion.Item eventKey="facts" className="mb-3">
                  <Accordion.Header>
                    <h6 className="accordianhead">
                      {parser(
                        policyData.wherecanigetmoreinformation.titlewhere
                      )}
                    </h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      {parser(
                        policyData.wherecanigetmoreinformation.definationimg
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            {/* </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion> */}
          </>
        )}
      </Container>
    </>
  );
}

export default Privacy;
