/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Loading from "../Loading";
import parser from "html-react-parser";
import axios from "axios";
import CommonBgComp from "../CommonBgComp";
import { replaceMortgagePossibleWithEvolutionAiMortgage } from "../Utils";
import "./index.css";
import { Helmet } from "react-helmet";
export default function TermsAndConditions() {
    const imgCDNURL = process.env.REACT_APP_CDN + "evolutionai/images/";
    const [termsAndConditionsData, setTermsAndConditionsData] = useState();

    useEffect(() => {
        /**
         * @author Akhilesh Rajput
         * @date 07/23/2024
         * The below function is used to fetch the terms and conditions data for mortgagepossible.com which
         * is stored in master JSON in S3 bucket. We will replace mortgagepossible with AngelAi.Life.
         * @returns JSON Object of the requested file
         */
        const fetchTermsAndConditions = async () => {
            try {
                await axios
                    .post(process.env.REACT_APP_TERMS_AND_CONDITIONS, { websiteName: 'mortgagepossible.com' })
                    .then((response) => {
                        let data = response.data.data;
                        // Replace Mortgage Possible with Evolution AI Mortgage
                        data = JSON.parse(replaceMortgagePossibleWithEvolutionAiMortgage(JSON.stringify(data)));
                        setTermsAndConditionsData(data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (e) {
                console.log(e);
            }
        };

        fetchTermsAndConditions();
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <div>
         <Helmet>
            <link rel="canonical" href="https://www.angelai.life/terms-and-conditions/"/>
        </Helmet>
            {termsAndConditionsData ? (
                <div>
                    <div>
                        <CommonBgComp
                            backgroundImage={imgCDNURL + "txdis-bg.webp"}
                            text="Terms and Conditions"
                            fontSizeDesktop={70}
                            fontSizeTablet={48}
                            fontSizeMobile={28}
                        />
                    </div>
                    <Container id="termsAndConditionsContainer">
                        <Row className="mt-4">
                            <Col md={12} className="angelaiDisclaimer">
                                {termsAndConditionsData.angelaidisclaimer.map((angelaidisclaimer, i) => (
                                    <p key={i}>
                                        {parser(
                                            termsAndConditionsData.angelaidisclaimerNotdba[i]
                                        )}
                                    </p>
                                ))}
                            </Col>
                            <Col md={12}>
                                <section className="section_terms">
                                    {termsAndConditionsData.defaultDisclaimer.map((defaultDisclaimerData, i) => (
                                        <p key={i}>
                                            {i === 0 ?
                                                <>&#34;</>
                                                : ""}
                                            {parser(defaultDisclaimerData)}
                                            {i === termsAndConditionsData.defaultDisclaimer.length - 1 ?
                                                <>&#34;</>
                                                : ""}
                                        </p>
                                    ))}
                                </section>
                            </Col>
                            {termsAndConditionsData.termsAndConditions.map((termsAndConditionsItem, j) => (
                                <Col md={12} key={j}>
                                    <div className="terms_header" />
                                    <div className="terms_content">
                                        <h3>{parser(termsAndConditionsItem.heading)}</h3>
                                        {termsAndConditionsItem.description.map((descriptionData, k) => (
                                            <p key={k}>
                                                {parser(descriptionData)}
                                            </p>
                                        ))}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </div>
            ) : (
                <Container>
                    <Row>
                        <Col sm={12} className="loadingSpinner text-center">
                            <Loading />
                        </Col>
                    </Row>
                </Container>
            )}
        </div>
    );
}
