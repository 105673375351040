/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 */
import React, { useEffect, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Loading from "../Loading";
import CommonBgComp from "../CommonBgComp";
import "./index.css";
import { Helmet } from "react-helmet";
export default function TexasDisclosure() {
    const imgCDNURL = process.env.REACT_APP_CDN+"evolutionai/images/";

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <>
        <Helmet>
            <link rel="canonical" href="https://www.angelai.life/txdis/"/>
        </Helmet>
        <Suspense fallback={<Loading />}>
            <div className="tx-dis">
            <div>
                        <CommonBgComp
                            backgroundImage={imgCDNURL+"txdis-bg.webp"}
                            text="Texas Disclosure"
                            fontSizeDesktop={70}
                            fontSizeTablet={48}
                            fontSizeMobile={30}
                        />
                    </div>
                <Container id="texasDisclosureContainer">
                    <Row>
                        <Col sm={12} className="mt-3 mb-3">
                            <p className="evolution-txdis-heading"><strong>Texas Complaint Notice:</strong></p>
                            <p>Figure: 7 TAC §81.200(c)</p>
                            <h4>TEXAS MORTGAGE BANKER DISCLOSURE</h4>
                            <p>"CONSUMERS WISHING TO FILE A COMPLAINT AGAINST A MORTGAGE BANKER OR A LICENSED MORTGAGE BANKER
                                RESIDENTIAL MORTGAGE LOAN ORIGINATOR SHOULD COMPLETE AND SEND A COMPLAINT FORM TO THE TEXAS
                                DEPARTMENT OF SAVINGS AND MORTGAGE LENDING, 2601 NORTH LAMAR, SUITE 201, AUSTIN, TEXAS 78705.
                                COMPLAINT FORMS AND INSTRUCTIONS MAY BE OBTAINED FROM THE DEPARTMENT'S WEBSITE AT{" "}
                                <a href="http://www.sml.texas.gov/" target="_blank" rel="noreferrer noopener" title="Texas Department of Savings and Mortgage Lending"> WWW.SML.TEXAS.GOV</a>.
                                A TOLL-FREE CONSUMER HOTLINE IS AVAILABLE AT <a href="tel:+18772765550">1-877-276-5550</a>.</p>
                            <p>THE DEPARTMENT MAINTAINS A RECOVERY FUND TO MAKE PAYMENTS OF CERTAIN ACTUAL OUT OF POCKET DAMAGES
                                SUSTAINED BY BORROWERS CAUSED BY ACTS OF LICENSED MORTGAGE BANKER RESIDENTIAL MORTGAGE LOAN
                                ORIGINATORS. A WRITTEN APPLICATION FOR REIMBURSEMENT FROM THE RECOVERY FUND MUST BE FILED WITH AND
                                INVESTIGATED BY THE DEPARTMENT PRIOR TO THE PAYMENT OF A CLAIM. FOR MORE INFORMATION ABOUT THE
                                RECOVERY FUND, PLEASE CONSULT THE DEPARTMENT'S WEBSITE AT{" "}
                                <a href="http://www.sml.texas.gov/" target="_blank" rel="noreferrer noopener" title="Texas Department of Savings and Mortgage Lending">WWW.SML.TEXAS.GOV</a>."</p>
                            <p>THIS DISCLOSURE NOTICE ONLY APPLIES TO RESIDENTIAL MORTGAGE LOANS ON REAL ESTATE LOCATED IN TEXAS.
                            </p>
                            <p><strong>Texas Servicing Disclosure:</strong></p>
                            <p>The following disclosure notice only applies to residential mortgage loans on real estate located in
                                Texas:</p>
                            <p>COMPLAINTS REGARDING THE SERVICING OF YOUR MORTGAGE SHOULD BE SENT TO THE DEPARTMENT OF SAVINGS
                                AND MORTGAGE LENDING, 2601 NORTH LAMAR, SUITE 201, AUSTIN, TX 78705. A TOLL-FREE CONSUMER
                                HOTLINE IS AVAILABLE AT <a href="tel:+18772765550">877-276-5550</a>.
                            </p>
                            <p>A complaint form and instructions may be downloaded and printed from the Department's website located
                                at <a href="http://www.sml.texas.gov/" target="_blank" rel="noreferrer noopener" title="Texas Department of Savings and Mortgage Lending">www.sml.texas.gov</a> or obtained from the department upon request by mail at the address above, by
                                telephone at its toll-free consumer hotline listed above, or by email at <a href="mailto:smlinfo@sml.texas.gov" title="Texas Department of Savings and Mortgage Lending">smlinfo@sml.texas.gov</a>.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Suspense>
        </>
    );
}