/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
*/
import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Modal,
    Button,
    Spinner,
} from "react-bootstrap";
import axios from "axios";
import "./signupform.css";
import HTMLReactParser from "html-react-parser";
import {Link} from "react-router-dom"

function trim(theStr) {
    return theStr.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
}

function getNumbers(inVal, isDouble, removeCharsFromPrefix) {
    var retVal = "";
    var okChars = "0123456789";
    var tmpVal = trim(inVal);
    var curChar = "";
    var isDotFound = false;

    if (tmpVal !== "") {
        for (var i = 0; i < tmpVal.length; i++) {
            curChar = tmpVal.charAt(i);

            if (okChars.indexOf(curChar) !== -1) {
                if (!isDotFound && curChar === "." && isDouble) {
                    isDotFound = true;
                    retVal = retVal + ".";
                }

                if (retVal === "" && removeCharsFromPrefix.indexOf(curChar) !== -1) {
                    //Do nothing
                } else {
                    retVal += curChar;
                }
            }
        }
    }

    return retVal;
}

function serialize(form) {
    let inputs = form.elements;
    let testArray = {};
    for (let i = 0; i < inputs.length - 1; i++) {
        testArray[inputs[i].id] = inputs[i].value;
    }
    return testArray;
}

// function getParameterByName(name, url = window.location.href) {
//   name = name.replace(/[\[\]]/g, "\\$&");
//   var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
//     results = regex.exec(url);
//   if (!results) return null;
//   if (!results[2]) return "";
//   return decodeURIComponent(results[2].replace(/\+/g, " "));
// }

function getFormURL() {
    if (
        window.location.href.split("/")[3] &&
        window.location.href.split("/")[4]
    ) {
        const temp = window.location.href.split("/")[4].replace("#", "");
        return "/" + window.location.href.split("/")[3] + "/" + temp;
    } else {
        return "/";
    }
}

export default function SignUpForm({closeForm,setShowSuccessModal,setShowFailModal,requestFromModal }) {
    const [validated, setValidated] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [leadNo, setLeadNo] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [consentValue, setConsentValue] = useState(true);
    const [comment, setComment] = useState();
    const [showContactForm, setShowContactForm] = useState(true);
    const [showConsenSection, setShowConsenSection] = useState(false);
    const [showSuccessModal1, setShowSuccessModal1] = useState(false);
    const [showFailModal1, setShowFailModal1] = useState(false);
    const [showClickToMoreOpt, setShowClickToMoreOpt] = useState(true);
    const [showPhotoConsentMoreOpt, setShowPhotoConsentMoreOpt] = useState(true);
    const queryParams = new URLSearchParams(window.location.search);
    const key = queryParams.get("key");
    const utmSource = queryParams.get("utm_source") || undefined;
    const utmMedium = queryParams.get("utm_medium") || undefined;
    const utmCampaign = queryParams.get("utm_campaign") || undefined;
    const utmContent = queryParams.get("utm_content") || undefined;
   
   
    const clearForm = () => {
        setLeadNo("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setComment("");
        setConsentValue(true);
    };
    const handleFailClose1 = () => {
        setShowFailModal1(false);
        setValidated(false);
    };
    const handleSuccessClose1 = () => {
        setShowSuccessModal1(false);
        setValidated(false);
        
    };
    const goBackToFirstPage = () => setShowConsenSection(false);
    const domainName = 'angelailife.com';   
    const language = 'en';  
  
    const [consentData, setConsentData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchConsentData = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_LEAD_GENERATION_CONSENT_JSON);
          const data = await response.json();
      
          const selectedDomain = data[domainName] ? domainName : 'swmc.com';  
          const domainData = data[selectedDomain];   
 
          const consent = {
            submitButtonConsent: domainData.submit_button_consent[language],   
            automatedPhoneSolicitationConsent: domainData.automated_phone_solicitation_consent[language],   
            version: domainData.version,  
          };
      
          setConsentData(consent);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
      useEffect(() => {
        fetchConsentData();
      }, []);

    //Below is the function to remove non-printable ASCII chars
    //and special chars from the pass string
    function removeNonAsciiSpecialCharacters(str) {
        //check if pass value is null or blank.
        //return empty string if pass value is null or blank
        if (str === null || str === "") {
            return "";
        } else {
            //convert the pass value to string
            str = str.toString();
            //remove non-printable ASCII chars and replace it with empty string
            str = str.replace(/[^\x20-\x7E]/g, "");
            //replace all special characters with empty string
            str = str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}0-9\[\]\\\/]/g, "");
            //return updated string to calling function
            return str;
        }
    }

    //first name validation
    const handleFirstName = (evt) => {
        let tempFirstName = evt.currentTarget.value;
        tempFirstName = removeNonAsciiSpecialCharacters(tempFirstName);
        if (tempFirstName) {
            setFirstName(tempFirstName.trim());
        } else {
            setFirstName("");
        }
    };

    useEffect(() => {
        if (consentData) {
          const consentElement = document.querySelector('.button-name');
          if (consentElement) {
            consentElement.innerText = 'clicking Submit';  
          }
        }
      }, [consentData]);
      
    const handleEmailAddress = (evt) => {
        let tempEmailAddress = evt.currentTarget.value;
        if (tempEmailAddress) {
            setEmail(tempEmailAddress.trim());
        } else {
            setEmail("");
        }
    };

    // Phone number validation
    const handleTelephoneNumber = (evt) => {
        //Save Phone number in local storage for Apply
        var tmpVal = trim(getNumbers(evt.currentTarget.value, false, "01"));
        var FirstThreeDigit = tmpVal.substring(0, 3);
        var FirstSixDigit = tmpVal.substring(0, 6);

        /* Below Pattern will check numbers between below ranges
                456, 555, 900,
                100, 101, ..., 199
                211, 311, ..., 911  ***811 removed
                370, 371, ..., 379
                960, 961, ..., 969
                800, 801, ..., 855  ***removed
                866, 877, 888, 800855
                */
        var patt =
            /^(1[0-9][0-9])|([2-7]11)|(911)|(37[0-9])|(96[0-9])|(456)|(555)|(866)|(877)|(888)|(900)$/;
        var patt2 = /^800855$/;
        if (patt.test(FirstThreeDigit)) {
            evt.currentTarget.value = "";
            return;
        } else if (patt2.test(FirstSixDigit)) {
            evt.currentTarget.value = "";
            return;
        }

        var curChar = "";
        var areaCode = "";
        var firstThree = "";
        var lastFour = "";
        var extension = "";

        if (tmpVal !== "") {
            for (var i = 0; i < tmpVal.length; i++) {
                curChar = tmpVal.charAt(i);

                if (i < 3) areaCode += curChar;
                if (i < 6 && i > 2) firstThree += curChar;
                if (i < 10 && i > 5) lastFour += curChar;
                if (i > 9) extension += curChar;
            }
        }

        var retVal = "";

        /* Do not put the parentesis if we only have the first three digits of the phone number.
                Once we are past that then start formatting the phone number. This was the main issue
                why the phone number couldn't be blanked out in it's entirety.*/
        if (areaCode !== "" && firstThree === "") retVal += areaCode;
        else if (areaCode !== "" && firstThree !== "")
            retVal += "(" + areaCode + ")";

        if (firstThree !== "") retVal += " " + firstThree;

        if (lastFour !== "") retVal += "-" + lastFour;

        if (extension !== "") retVal += " x" + extension;
        else setPhone(retVal);
    };

    const showPhoneSoliticationConsent = () => {
        setShowClickToMoreOpt(false);
    };

    const hidePhoneSoliticationConsent = () => {
        setShowClickToMoreOpt(true);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setFormLoading(true);
            const signupFormData = document.querySelector("#signupForm");
            const getData = serialize(signupFormData);
            const signupForm = document.getElementById("signupForm");
            if (!showConsenSection) {
                let tempname = getData.firstName;
                let firstName_, lastName_;
                if (tempname.includes(" ")) {
                    const name = tempname.split(" ");
                    firstName_ = name[0];
                    lastName_ = name.slice(1).join(" ");
                    lastName_ = lastName_.trim();
                } else {
                    firstName_ = tempname;
                }
                firstName_ = firstName_.trim();
                const phoneNumber_ = getData.phone;
                const email_ = getData.email;
                const companyName_ = "";
                const comments_ = getData.comment;
                let formUrl = getFormURL();
                const chs_name = getData["chs:name"];
                const consent_version= consentData ? consentData.version : undefined;
                const consent_version_text = consentData ?  consentData.automatedPhoneSolicitationConsent.content: undefined;
                

                axios
                    .post(process.env.REACT_APP_API_EMAIL, {
                        firstName: firstName_,
                        lastName: lastName_,
                        phone: phoneNumber_,
                        email: email_,
                        companyName: companyName_,
                        referrerSource: "AngelAi.Life (angelai.life)",
                        comments: comments_,
                        "chs:name": chs_name,
                        utmSource: utmSource,
                        utmMedium: utmMedium,
                        utmCampaign: utmCampaign,
                        utmContent: utmContent,
                        formUrl: formUrl,
                        leadSource: "ANGELAILIFE",
                        TCPA_Consent_Given: consentValue,
                        consentVersion: consent_version,
                        consentVersionText: consent_version_text,                        
                    })
                    .then((res) => {
                        setLeadNo(res.data.success.brokerId);
                        // show the consent section.
                        setShowConsenSection(true);
                        setFormLoading(false);
                    })
                    .catch(function (error) {
                        setShowFailModal(true);
                        setShowSuccessModal(false);
                        signupForm.classList.remove("was-validated");
                        signupForm.reset();
                        setFormLoading(false);
                    });
            } else {
                // We need to update the details of lead again as there might be the case user may
                // select I do not consent in the consent section after lead is generated
                const leadNo_ = getData.leadNo;
                let tempname = getData.firstName;
                let firstName_, lastName_;
                if (tempname.includes(" ")) {
                    const name = tempname.split(" ");
                    firstName_ = name[0];
                    lastName_ = name.slice(1).join(" ");
                    lastName_ = lastName_.trim();
                } else {
                    firstName_ = tempname;
                }
                firstName_ = firstName_.trim();

                const phoneNumber_ = getData.phone;
                const email_ = getData.email;
                const comments_ = getData.comment;
                const consent_version= consentData ? consentData.version : undefined;
                const consent_version_text = consentData ?  consentData.automatedPhoneSolicitationConsent.content: undefined;
                
                axios
                    .post(process.env.REACT_APP_API_EMAIL, {
                        leadId: leadNo_,
                        firstName: firstName_,
                        lastName: lastName_,
                        phone: phoneNumber_,
                        email: email_,
                        comments: comments_,
                        leadSource: "ANGELAILIFE",
                        TCPA_Consent_Given: consentValue,
                        consentVersion: consent_version,
                        consentVersionText: consent_version_text,                        
                    })
                    .then((res) => {
                        if (requestFromModal) {
                            setLeadNo(leadNo_);
                            setShowContactForm(true);
                            setShowSuccessModal(true);
                            closeForm();
                            setShowConsenSection(false);
                            signupForm.classList.remove("was-validated");
                            signupForm.reset();
                            clearForm();
                            setFormLoading(false);
                        }
                        setLeadNo(leadNo_);
                        setShowContactForm(true);
                        setShowConsenSection(false);
                        setShowSuccessModal1(true);
                        signupForm.classList.remove("was-validated");
                        signupForm.reset();
                        clearForm();
                        setFormLoading(false);
                        // closeForm();
                        // setShowSuccessModal1(true);
                    })
                    .catch(function (error) {
                        if (requestFromModal) {
                            setShowFailModal(true);
                            setShowSuccessModal(false);
                            signupForm.classList.remove("was-validated");
                            signupForm.reset();
                            setFormLoading(false);
                        }
                        setShowFailModal1(true);
                        setShowSuccessModal1(false);
                        signupForm.classList.remove("was-validated");
                        signupForm.reset();
                        setFormLoading(false);
                    });
            }
        }
        setValidated(true);
    };

    useEffect(() => {
        if (key) {
            axios
                .post(
                    process.env.REACT_APP_SUNSOFT_API_GET_SESSION_KEY,
                    { key: key },
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                )
                .then((response) => {
                    let userData = response.data.parameters;
                    if (userData.borrowerName) {
                        // If the borrower name contains a space, split the first and last name.
                        let name = userData.borrowerName.split(" ");
                        setFirstName(name[0]);
                        setLastName(name.slice(1).join(" "));
                    } else {
                        // If there is no borrower name, set the first and last name accordingly.
                        if (userData.borrowerFirstName) {
                            setFirstName(userData.borrowerFirstName);
                        }
                        if (userData.borrowerLastName) {
                            setLastName(userData.borrowerLastName);
                        }
                    }
                    setPhone(userData.borrowerPhone);
                    setEmail(userData.emailAddress);

                    return response;
                })
                .catch((error) => {
                    console.error("Get GET_SESSION_KEY Error: ", error);
                });
        }
    }, []);

    return (
        <>
        <div id="contact-wrapper" className="formWrapper pad-form">
            <div className="formHeader" >
                <h3>Get Started</h3>
                {/* <p className="text-center">We would love to hear your feedback.</p> */}
            </div>
            <div className="formBody">
                <Container className="form-container">
                    <Row>
                        <Col>
                            {showContactForm && (
                                <Form
                                    id="signupForm"
                                    autoComplete="off"
                                    method="POST"
                                    noValidate
                                    onSubmit={handleSubmit}
                                    validated={validated}
                                >
                                    <Row>
                                        <Col sm={12} className="d-none">
                                            <Form.Group controlId="leadNo" className="form-group">
                                                <Form.Control
                                                    aria-label="Lead Number"
                                                    placeholder="Lead Number"
                                                    type="text"
                                                    value={leadNo}
                                                    disabled
                                                    className="inside-placeholder"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col
                                            sm={12}
                                            className={showConsenSection ? "d-none" : "d-block"}
                                        >
                                            <Form.Group controlId="firstName" className="form-group">
                                                <Form.Control
                                                    aria-label="Name"
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    onBlur={handleFirstName}
                                                    placeholder="Name"
                                                    required
                                                    type="text"
                                                    value={firstName}
                                                    className="inside-placeholder"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid Name.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col
                                            sm={12}
                                            className={showConsenSection ? "d-none" : "d-block"}
                                        >
                                            <Form.Group controlId="email" className="form-group">
                                                <Form.Control
                                                    aria-label="Email ID"
                                                    onChange={handleEmailAddress}
                                                    pattern="^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9\-]+(?:\.[a-zA-Z0-9\-]{2,64})$"
                                                    placeholder="Email ID"
                                                    required
                                                    type="text"
                                                    value={email}
                                                    className="inside-placeholder"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid Email ID.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col
                                            sm={12}
                                            className={showConsenSection ? "d-none" : "d-block"}
                                        >
                                            <Form.Group controlId="phone" className="form-group">
                                                <Form.Control
                                                    aria-label="Phone Number"
                                                    maxLength={21}
                                                    minLength={14}
                                                    onChange={handleTelephoneNumber}
                                                    pattern="^\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})[ ]*(( |x){1}[ ]?([0-9]){1,7}){0,1}$"
                                                    placeholder="Phone Number"
                                                    required={email === ""}
                                                    type="text"
                                                    value={phone}
                                                    className="inside-placeholder"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid Phone Number.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col
                                            sm={12}
                                            className={showConsenSection ? "d-none" : "d-block"}
                                        >
                                            <Form.Group controlId="comment" className="form-group">
                                                <Form.Control
                                                    rows="3"
                                                    aria-label="comment"
                                                    placeholder="Message"
                                                    as="textarea"
                                                    value={comment}
                                                    onChange={(e) => setComment(e.target.value)}
                                                    className="inside-placeholder"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col
                                            sm={12}
                                            className={showConsenSection ? "d-block" : "d-none"}
                                        >
                                            <div className="d-flex">
                                                {showClickToMoreOpt ? (
                                                    <h6
                                                        className="consentSmall"
                                                        onClick={showPhoneSoliticationConsent}
                                                    >
                                                        {"Do you consent to Automated Phone Solicitation?"}{" "}
                                                        <span
                                                            id="showConsentText"
                                                            className="consentLink showConsentText"
                                                            title="Show Phone Solicitation Consent"
                                                        >
                                                            (click to show more)
                                                        </span>
                                                    </h6>
                                                ) : (
                                                    <h6
                                                        className="consentSmall"
                                                        onClick={hidePhoneSoliticationConsent}
                                                    >
                                                        {"Do you consent to Automated Phone Solicitation?"}{" "}
                                                        <span
                                                            id="hideConsentText"
                                                            className="consentLink hideConsentText"
                                                            title="Hide Phone Solicitation Consent"
                                                        >
                                                            (click to show less)
                                                        </span>
                                                    </h6>
                                                )}
                                            </div>

                                            {!showClickToMoreOpt ? (
                                                <p
                                                    id="phoneSolicitationTerms"
                                                    className="consent-font pb-0"
                                                >
                                                    {consentData?.automatedPhoneSolicitationConsent && (
                                                    <>
                                                    {HTMLReactParser(consentData.automatedPhoneSolicitationConsent.content)}
                                                    </>
                                                    )}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                        <Col
                                            sm={12}
                                            className={showConsenSection ? "d-block" : "d-none"}
                                        >
                                            <Form.Check
                                                aria-label="I Consent"
                                                defaultChecked
                                                className="checkIConsent signupformconsent"
                                                id="iConsent"
                                                label="I Consent"
                                                name="checkIConsent"
                                                onClick={() => setConsentValue(true)}
                                                type="radio"
                                            />
                                            <Form.Check
                                                aria-label="I DO NOT Consent"
                                                className="checkIConsent signupformconsent"
                                                id="iDoNotConsent"
                                                label="I DO NOT Consent"
                                                name="checkIConsent"
                                                onClick={() => setConsentValue(false)}
                                                type="radio"
                                            />
                                        </Col>
                                        <Col
                                            sm={12}
                                            className={showConsenSection ? "d-none" : "d-block"}
                                        >
                                        {consentData?.submitButtonConsent && (
                                        <p className="consent-font">
                                        {HTMLReactParser(consentData.submitButtonConsent.content)}
                                        </p>
                                        )}

                                        </Col>
                                        <Col
                                            sm={12}
                                            className={showConsenSection ? "d-none" : "d-block"}
                                        >
                                            <div className="divflex">
                                                <div>
                                                    <Button className="btn-submit" type="submit">
                                                        {formLoading ? (
                                                            <>
                                                                <Spinner
                                                                    animation="border"
                                                                    aria-hidden="true"
                                                                    as="span"
                                                                    role="status"
                                                                    size="sm"
                                                                />{" "}
                                                                Please wait...
                                                            </>
                                                        ) : (
                                                            <>Submit</>
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col
                                            sm={12}
                                            className={showConsenSection ? "d-block" : "d-none"}
                                        >
                                            <div className="divflex">
                                                <div className="backBtnDiv">
                                                    <Button
                                                        className="btn-submit"
                                                        type="button"
                                                        onClick={goBackToFirstPage}
                                                    >
                                                        <>Back</>
                                                    </Button>
                                                </div>
                                                <div className="continueBtnDiv btnAdjustDiv">
                                                    <Button
                                                        className="btn-submit"
                                                        type="submit"
                                                        variant="success"
                                                    >
                                                        {formLoading ? (
                                                            <>
                                                                <Spinner
                                                                    animation="border"
                                                                    aria-hidden="true"
                                                                    as="span"
                                                                    role="status"
                                                                    size="sm"
                                                                />{" "}
                                                                Please wait...
                                                            </>
                                                        ) : (
                                                            <>Continue</>
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Col>
                    </Row>
                </Container>
                <Modal
                    size="lg"
                    centered
                    show={showSuccessModal1}
                    onHide={handleSuccessClose1}
                    backdrop="static"
                >
                    <Modal.Header closeButton />
                    <Modal.Body className="text-center">
                        <p className="thanktext">Thank you for sharing your feedback!</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleSuccessClose1}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    size="lg"
                    centered
                    show={showFailModal1}
                    onHide={handleFailClose1}
                    backdrop="static"
                >
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <p className="thanktext">
                            Due to technical difficulties your request could not be processed.
                            Please contact us at&nbsp;
                            <a href={"mailto:inquiry@swmc.com"} className="form-link">
                                inquiry@swmc.com
                            </a>
                            .
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleFailClose1}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
        </>
    );
}
